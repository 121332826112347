// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qL d_gs d_cp";
export var heroHeaderCenter = "q_gt d_gt d_cp d_ds";
export var heroHeaderRight = "q_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "q_qM d_gp d_cs";
export var heroParagraphCenter = "q_gq d_gq d_cs d_ds";
export var heroParagraphRight = "q_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "q_qN d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "q_qP d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "q_qQ d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "q_qR d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "q_qS d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "q_qT t_qT";
export var heroExceptionNormal = "q_qV t_qV";
export var heroExceptionLarge = "q_qW t_qW";
export var Title1Small = "q_qX t_qX t_rn t_rp";
export var Title1Normal = "q_qY t_qY t_rn t_rq";
export var Title1Large = "q_qZ t_qZ t_rn t_rr";
export var BodySmall = "q_q0 t_q0 t_rn t_rH";
export var BodyNormal = "q_q1 t_q1 t_rn t_rJ";
export var BodyLarge = "q_q2 t_q2 t_rn t_rK";