// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "s_rd d_bz";
export var storyRowWrapper = "s_hv d_hv d_bG";
export var storyLeftWrapper = "s_rf d_bw d_bL";
export var storyWrapperFull = "s_rg d_cz";
export var storyWrapperFullLeft = "s_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "s_mC d_hw";
export var storyLeftWrapperCenter = "s_rh d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "s_rj d_hC";
export var storyHeader = "s_rk d_hB d_s d_cp";
export var storyHeaderCenter = "s_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "s_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "s_rl d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "s_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "s_q8 d_fc d_W";
export var imageWrapperFull = "s_rm d_s d_D d_bb d_W";