// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qc d_fl d_bC d_dr";
export var alignLeft = "p_qd d_fl d_bC d_dr";
export var alignDiscCenter = "p_qf d_fm d_bz d_ds";
export var alignCenter = "p_bL d_fm d_bz d_ds";
export var alignDiscRight = "p_qg d_fn d_bD d_dt";
export var alignRight = "p_qh d_fn d_bD d_dt";
export var footerContainer = "p_qj d_dS d_bS";
export var footerContainerFull = "p_qk d_dQ d_bS";
export var footerHeader = "p_kc d_kc";
export var footerTextWrapper = "p_ql d_s";
export var footerDisclaimerWrapper = "p_kk d_kk d_cg";
export var badgeWrapper = "p_qm d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "p_kl d_kl d_bw";
export var footerDisclaimerLeft = "p_km d_km d_bw";
export var verticalTop = "p_qn d_bw d_bF d_bK d_bH";
export var firstWide = "p_qp";
export var disclaimer = "p_qq";
export var socialDisclaimer = "p_qr";
export var left = "p_qs";
export var wide = "p_qt d_cv";
export var right = "p_qv d_bG";
export var line = "p_fg d_fh d_cr";
export var badgeDisclaimer = "p_qw d_by d_bL d_bF";
export var badgeContainer = "p_qx d_bw d_bD d_bL";
export var badge = "p_qy";
export var padding = "p_qz d_c4";
export var end = "p_qB d_bD";
export var linkWrapper = "p_qC d_dx";
export var link = "p_mF d_dx";
export var colWrapper = "p_qD d_ct";
export var media = "p_qF d_bt d_dv";
export var itemRight = "p_qG";
export var itemLeft = "p_qH";
export var itemCenter = "p_qJ";
export var exceptionWeight = "p_qK t_rQ";