/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import HTMLParser from 'html-react-parser';
import clsx from 'clsx';

import { formColor } from '../../helper';
import fontSizeSwitch from '../../helpers/fontSizeSwitch';

import * as styles from './styles.module.css';

const CustomizableButton = ({ className, data, themeData, invert, label = '', fullWidth, ...rest }) => {
  let bThickness = 0;
  let borderRadius = `${themeData.button.radius}px`;
  let bColorHex = themeData.colors[2];
  let backgroundColor = { solid: themeData.colors[0] };
  let font = '#FFF';
  let boxShadow;

  if (data?.styles) {
    if (data.styles.border) {
      if (data.styles.border.active && data.styles.border.color) bColorHex = data.styles.border.color;

      bThickness = data.styles.border.active === true ? `${data.styles.border.thickness}px` : bThickness;
      borderRadius = data.styles.border.active === true ? `${data.styles.border.radius}px` : borderRadius;
    }

    if (data.styles.shadow) {
      const x = data.styles.shadow.active ? data.styles.shadow.x : 0;
      const y = data.styles.shadow.active ? data.styles.shadow.y : 0;
      const blur = data.styles.shadow.active ? data.styles.shadow.blur : 0;
      const spread = data.styles.shadow.active ? data.styles.shadow.spread : 0;

      let sColor;
      if (data.styles.shadow.active) {
        let shadow = data.styles.shadow.color;
        if (!data.styles.shadow.color) [, , shadow] = themeData.colors;

        sColor = formColor(
          { solid: shadow },
          false,
          data.styles.shadow.opacity,
          undefined,
          themeData.colors,
          invert,
        );
      }

      boxShadow = data.styles.shadow.active
        ? `${x}px ${y}px ${blur}px ${spread}px ${sColor.backgroundColor}`
        : undefined;
    }

    if (data.styles.fontColor && data.styles.fontColor.color) font = data.styles.fontColor.color;

    if (data.styles.backgroundColor?.solid || data.styles.backgroundColor?.gradient?.from) {
      ({ backgroundColor } = data.styles);
    }
  }

  const fontColor = formColor(
    { solid: font },
    undefined,
    data.styles.fontColor ? data.styles.fontColor.opacity : 1,
    undefined,
    themeData.colors,
    invert
  );

  const bgColor = formColor(
    backgroundColor,
    false,
    data.styles.backgroundColor ? data.styles.backgroundColor.opacity : 1,
    undefined,
    themeData.colors,
    invert
  );

  const bColor = formColor(
    { solid: bColorHex },
    false,
    data?.styles?.border?.opacity || 1,
    undefined,
    themeData.colors,
    invert
  );
  const border = `${bColor.backgroundColor} solid ${bThickness}`;

  if (fullWidth) borderRadius = '';
  const fontFamily = themeData.typography.button.name;
  const fontSize = fontSizeSwitch(themeData.typography.button.fontSize);
  const { textTransform } = themeData.typography.button;
  const fontWeight = themeData.typography.button.weight;

  const style = {
    borderRadius,
    border,
    boxShadow,
    fontFamily,
    fontSize,
    textTransform,
    fontWeight,
    color: fontColor ? fontColor.backgroundColor : undefined,
  };

  if (bgColor) {
    if (bgColor.background) style.background = bgColor.background;
    else style.backgroundColor = bgColor.backgroundColor;
  }

  let icon;
  if (data && data.icon && data.icon.active === true) {
    style.paddingLeft = '14px';
    let color = data.icon.color || '#000000';
    if (color) {
      color = formColor({ solid: color }, false, 1, undefined, themeData.colors, invert).backgroundColor;
    }

    icon = (
      <span
        className={`entypo ${data.icon.class}`}
        aria-hidden
        style={{
          marginRight: label ? '16px' : '0',
          fontSize: data.icon.size,
          color,
        }}
      />
    );
  }

  return (
    <button className={clsx(styles.btn, fullWidth && styles.full, className)} style={style} {...rest}>
      {icon}
      {HTMLParser(label)}
    </button>
  );
};

export default CustomizableButton;
