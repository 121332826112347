// extracted by mini-css-extract-plugin
export var lbContainerOuter = "x_sN";
export var lbContainerInner = "x_sP";
export var movingForwards = "x_sQ";
export var movingForwardsOther = "x_sR";
export var movingBackwards = "x_sS";
export var movingBackwardsOther = "x_sT";
export var lbImage = "x_sV";
export var lbOtherImage = "x_sW";
export var prevButton = "x_sX";
export var nextButton = "x_sY";
export var closing = "x_sZ";
export var appear = "x_s0";